import { StyleSheet } from "aphrodite/no-important";
import { paddingRem, baseTheme } from "../baseTheme";

export const styles = StyleSheet.create({
  quotes: {
    columnCount: 1, // Masonry effect with 3 columns
    columnGap: paddingRem(3), // Space between columns
    listStyle: "none",
    padding: 0,

    [baseTheme.screenSize.medium]: {
      columnCount: 2,
    },
  },
  quoteWrapper: {
    display: "inline-block", // Ensures proper masonry layout
    width: "100%", // Forces full width within its column
    background: "#fff", // Light grey background
    padding: paddingRem(3),
    marginBottom: paddingRem(3), // Space between quotes
    breakInside: "avoid", // Prevents quotes from splitting between columns
  },
  quote: {
    fontSize: baseTheme.fontSize[600],
    lineHeight: baseTheme.lineHeight[600],
    color: baseTheme.colour.grey[200],
    fontStyle: "italic",
    display: "block",
    paddingBottom: paddingRem(3),
  },
  link: {
    textDecoration: "none",
  },
});
