import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./BannerStyles";
import Paragraph from "./Paragraph";
import Link from "./Link";
import ReactGA from "react-ga";

export default class Callout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: true,
    };
  }
  componentDidMount() {
    if (window.sessionStorage.getItem("firstLoadDone") === null) {
      this.setState({
        animate: true,
      });

      window.sessionStorage.setItem("firstLoadDone", 1);
    } else {
      this.setState({
        animate: false,
      });
    }
  }
  render() {
    return (
      <div
        className={css([
          !this.state.animate && styles.banner,
          this.state.animate && styles.bannerAnimation,
        ])}
      >
        <div className={css(styles.bannerContent)}>
          <Paragraph size="small" type="onBlack">
            <div className={css(styles.emojiWrapper)}>
              <span
                role="img"
                className={css(styles.emoji)}
                aria-labelledby="Pointing Right"
              >
                👉
              </span>
            </div>
            Available July 2025 for product and service design contracts.{" "}
            <Link
              onClick={() => {
                ReactGA.event({
                  category: "Contact",
                  action: "Get in touch from banner",
                });
              }}
              href="/contact"
            >
              Get in touch
            </Link>
            <div
              className={css([
                styles.emojiWrapper,
                styles.emojiWrapperTrailing,
              ])}
            >
              <span
                role="img"
                className={css(styles.emoji)}
                aria-labelledby="Pointing Left"
              >
                👈
              </span>
            </div>
          </Paragraph>
        </div>
      </div>
    );
  }
}
