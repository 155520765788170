import { StyleSheet } from "aphrodite/no-important";
import { baseTheme, paddingRem } from "../baseTheme";

export const styles = StyleSheet.create({
  cite: {
    textAlign: "left",
    margin: "0 auto",
    display: "flex",
    alignContent: "center",
  },
  citeImage: {
    height: paddingRem(7),
  },
  citeName: {
    color: baseTheme.colour.brand.primary,
    textDecoration: "underline",
  },
  citeDetail: {
    marginLeft: paddingRem(3),
    alignSelf: "center",
  },
  citeDescription: {
    textDecoration: "none",
  },
});
