import { StyleSheet } from "aphrodite/no-important";
import { paddingRem, baseTheme } from "../baseTheme";

const translateKeyframes = {
  "0%": {
    transform: "translateX(0)",
  },
  "50%": {
    transform: `translateX(${paddingRem(1)})`,
  },
  "100%": {
    transform: "translateX(0)",
  },
};
const flashKeyframes = {
  "0%": {
    background: baseTheme.colour.grey[100],
  },
  "50%": {
    background: baseTheme.colour.grey[200],
  },
  "100%": {
    background: baseTheme.colour.grey[100],
  },
};

export const styles = StyleSheet.create({
  emojiWrapper: {
    display: "inline-block",
    animationName: translateKeyframes,
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
    paddingRight: paddingRem(2),
  },
  emojiWrapperTrailing: {
    paddingLeft: paddingRem(2),
    paddingRight: 0,
  },
  banner: {
    background: baseTheme.colour.grey[100],
  },
  bannerAnimation: {
    animationName: flashKeyframes,
    animationDuration: ".75s",
    animationIterationCount: "5",
    animationFillMode: "forwards",
    animationTimingFunction: "ease-in-out",
  },
  bannerContent: {
    maxWidth: baseTheme.appMaxWidth,
    margin: "0 auto",
    textAlign: "center",

    padding: paddingRem(2),

    // [baseTheme.screenSize.small]: {
    //   padding: `0 ${paddingRem(9)}`,
    //   display: "flex",
    // },
  },
});
