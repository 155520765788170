import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./CiteStyles";
import { globalStyles } from "./GlobalStyles";

import Title500 from "./Title500";
import Paragraph from "./Paragraph";

export default class Cite extends Component {
  render() {
    return (
      <div class={css(styles.cite)}>
        <img
          className={css([globalStyles.avatar, styles.citeImage])}
          src={this.props.img}
          alt=""
        />
        <div className={css(styles.citeDetail)}>
          <Title500 spacing="condensed">
            <strong className={css(styles.citeName)}>{this.props.name}</strong>
          </Title500>
          <Paragraph size="small">
            <span className={css(styles.citeDescription)}>
              {this.props.description}
            </span>
          </Paragraph>
        </div>
      </div>
    );
  }
}
