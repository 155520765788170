import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./CommentsDetailStyles";
import Paragraph from "./Paragraph";
import Cite from "./Cite";

const quotes = [
  {
    name: "Amir Ullah",
    role: "Senior Product Designer",
    link: "https://www.linkedin.com/in/amir-ullah-27043015/", // Add the correct LinkedIn URL if available
    image: "/images/testimonials/amirullah.jpeg",
    quote:
      "Luke is a fantastic product designer with a wealth of experience and technical knowledge. I really enjoyed my time working with Luke and was impressed by his tenacity and agility to create a new design system from scratch and deliver work to a high quality. Luke is one of the good guys and deserves to work in a great team, I have no problem in recommending him.",
  },
  {
    name: "Simon Nixon",
    role: "Customer-Centred Design, Coach & Mentor, Product & Delivery, at bp",
    link: "https://www.linkedin.com/in/simonnixon",
    image: "/images/testimonials/simonnixon.jpeg",
    quote:
      "Luke’s work at bp was exceptional. He built and led a superbly talented team of design engineers. Luke’s depth of knowledge is endless in design systems but he can go much wider across all the different areas of Design. I sincerely hope our paths cross again in the future.",
  },
  {
    name: "Ed Santana",
    role: "Specialist in Design Direction, Strategy, Leadership, Operations, Service / Product Design & UX",
    link: "https://www.linkedin.com/in/edwardsantana",
    image: "/images/testimonials/edsantana.jpeg",
    quote:
      "“The product builds the design system” - Luke’s mantra, which is testament to his ability to listen to and understand the needs of a huge global portfolio of programs and deliver a solution that brings value not only to the customer experience but the teams that use it. During my time working with Luke, he never faltered on his dedication to the work and quality. If he happens to be on the market, snap him up. Quick.",
  },
  {
    name: "Suzannah Devereux",
    role: "Emerging Technology Consultant, Product, Design & Strategy",
    link: "https://www.linkedin.com/in/suzannahdevereux",
    image: "/images/testimonials/suzannahdevereux.jpeg",
    quote:
      "Luke is a phenomenal design leader. He brings diligence, structure, and buckets of creativity to any team, and all with a smile and a fantastic sense of humour. It’s a true pleasure to work with Luke; he’s always ready to help and fosters a truly collaborative working environment. I’d work with him again in a heartbeat!",
  },
  {
    name: "Alex Gunningham",
    role: "Frontend Engineer",
    link: "https://www.linkedin.com/in/alex-gunningham-196742a3",
    image: "/images/testimonials/alexgunningham.jpeg",
    quote:
      "Luke is an exceptional professional, his knowledge on design systems is astounding and his approach to leading a team is just as impressive. Always approachable and honest, I highly recommend Luke for any lead/design role.",
  },
  {
    name: "Stuart Seddon",
    role: "Senior Product Designer at SO Energy",
    link: "https://www.linkedin.com/in/stuseddon",
    image: "/images/testimonials/stuartseddon.jpeg",
    quote:
      "If you need a team lead, then there aren't many better. He's blessed with soft skills such as communication, conflict management, creativity, and problem-solving. Luke is particularly good at dealing with challenging stakeholders without compromising the integrity of the project. Finally, he's just a top person. Any team would be levelled up with him in it.",
  },
  {
    name: "Michael Ruocco",
    role: "Lead Product Designer | Expert in Creating Seamless Customer Experiences & Human-Centered Solutions",
    link: "https://www.linkedin.com/in/michaelruocco",
    image: "/images/testimonials/michaelruocco.jpeg",
    quote:
      "One of the best I've worked with concerning the implementation of design systems and all that entails, and I wouldn't hesitate to work with Luke again. A real team player. Great at managing. He codes, and when needs arise in designing at the digital coal-face, he's with you, shoulder to shoulder... highly recommended.",
  },
  {
    name: "Ross Phelps",
    role: "Service Design, Product Design & UX | Design Leadership",
    link: "https://www.linkedin.com/in/rossphelps",
    image: "/images/testimonials/rossphelps.jpeg",
    quote:
      "Luke is an exceptional Design Leader who has an inherent understanding and application of Design Thinking at a programme level. His motivational attributes galvanise his team to deliver within a challenging landscape, as well as being everyone’s right-hand man on a social. You will be sorely missed, sir. I look forward to working together again in the future!",
  },
  {
    name: "Rob Slonski",
    role: "UX Designer | Experience & Interaction Design",
    link: "https://www.linkedin.com/in/robsonslonski",
    image: "/images/testimonials/robslonski.jpeg",
    quote:
      "I consider Luke the best Design Lead I've had the privilege of working with. He is the most articulated professional I’ve seen in this business so far, and his ability to manage a design team, interpret client requirements, champion a vision, and deal with development hurdles is just impeccable. Great professional, person of character, strong values, and initiative. Hope I have an opportunity to work with him on a new project in the future.",
  },
  {
    name: "Jonathan Lines",
    role: "Freelance UX/Visual Designer",
    link: "https://www.linkedin.com/in/jonathanlines",
    image: "/images/testimonials/jonathanlines.jpeg",
    quote:
      "Luke is a well-organised, exceptional leader who has an in-depth knowledge of design and business processes as well as people management. He is always hard-working and approachable and is a pleasure to work for. He punches way above his weight, and I expect him to go on to bigger and better things in the industry as a Product Design Lead or Design System Lead. I'd certainly work with him again and can only recommend that someone snaps him up before it's too late.",
  },
  {
    name: "Jonathan Shaer",
    role: "Corporate Communications | Content | Transformation",
    link: "https://www.linkedin.com/in/jonathan-shaer/",
    image: "/images/testimonials/jonathanshaer.jpeg",
    quote:
      "Luke is a brilliant leader. Always calm, composed, and looking out for his team. Luke is extremely logical, well-reasoned, and rational when it comes to planning and delivery and problem-solving in general. His ability to navigate a complex stakeholder environment with empathy, professionalism, and a ‘customer-first’ approach is exceptional. He also digs in and helps with the nitty-gritty and has an extraordinary eye for detail. A real leader by example! Any team will be lucky to have Luke, and I very much hope to cross paths again in the future.",
  },
  {
    name: "Steve Edson",
    role: "Laravel / Vue.js / PHP / JavaScript Developer",
    link: "https://www.linkedin.com/in/steveedson90/",
    image: "/images/testimonials/steveedson.jpeg",
    quote:
      "Ever the optimist. When everything is on fire and falling apart, Luke will be the person to remain calm, assess the situation, and come up with the right solution, all while staying positive and lifting the morale of everyone around him. Luke is also that rare unicorn who can speak both design and dev. He has an incredible eye for design, but also has the technical knowledge to understand how to deliver it (and even create a React component or two himself!). You would be a fool not to have him on your team.",
  },
  {
    name: "Jack Horton",
    role: "Programme Manager",
    link: "https://www.linkedin.com/in/jack-horton/",
    image: "/images/testimonials/jackhorton.jpeg",
    quote:
      "Good leaders are hard to find. Great leaders are like gold dust, and I would put Luke into that category. Luke has a perfect blend of Design, Engineering, and People Leadership and the ability to manage the demands and politics of building a global Design System. He has a rare ability to connect with people from the off, shown in the fantastic team he made at bp – people listen to Luke because he knows his craft and inspires. Luke puts his money where his mouth is by measuring outcomes that having a Design System can deliver – working with Senior Leadership to prove the benefits of having a Design System in a large-scale blue-chip organisation. On a personal note, Luke is a great guy who puts people at the heart of everything. He ensures everyone's voice is heard and is not afraid to tackle complex topics or conversations with all levels of seniority. I highly recommend Luke not just to create a world-class Design System but to build a passionate and focused team.",
  },
  {
    name: "Paul Ridgway",
    role: "Founder and Director at The Curve",
    link: "https://www.linkedin.com/in/pdridgway/",
    image: "/images/testimonials/paulridgway.jpeg",
    quote:
      "Luke made the user experience, customer journey and graphical design processes very straightforward for us, after a detailed face-to-face session he was able to deliver wireframes, interactive mockups and assets quickly and autonomously. He worked as if he was part of the team, treating our problems as his own and helped us come up with solutions that would work well for the end-users.",
  },
];

export default class IndustriesDetail extends Component {
  render() {
    return (
      <ul class={css(styles.quotes)}>
        {quotes.map((item, index) => (
          <li className={css(styles.quoteWrapper)} key={index}>
            <q className={css(styles.quote)}>{item.quote}</q>
            <a
              href={item.link}
              className={css(styles.link)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Cite
                img={item.image}
                name={item.name}
                description={<Paragraph size="small">{item.role}</Paragraph>}
              />
            </a>
          </li>
        ))}
      </ul>
    );
  }
}
